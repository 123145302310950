// export const API_HOST = "http://10.10.2.73:5050/";
export const API_HOST = "https://stage-api.collatix.com/";
// export const API_HOST = ""https://stage-api.collatix.com/";


//Polygon testnet
// export const RPC_URL = "https://api.instanodes.io/polygon-testnet/?apikey=Jhfy6yowHItfm0imX8JFQeEbR8jlJfzQ";
// export const CHAIN_ID = 80002;
// export const NETWORK_NAME = "Polygon Amoy";
// export const CURRENCY = "POL";
// export const NETWORK_DECIMALS = 18;
// export const EXPLORER_LINK = "https://amoy.polygonscan.com/";
// export const COMPTROLLER_ADDRESS = "0x7C24D649fC875B2F66cE0b812852E9Cde93e80dd";
// export const PRICE_ORACLE_ADDRESS =
//   "0x62AD71dbB9b01EE7737DE4144DB5Be8D11488aEa";
// export const FACTORY_ADDRESS = "0xEc3e72472A7959050eD0eBEcF249a6f1afbb67aD";

//Bsc Testnet
// export const RPC_URL =
//   "https://bsc-testnet.blockpi.network/v1/rpc/94ae482e572ad23780d660501f96c70fb3e61f18";
// export const CHAIN_ID = 97;
// export const NETWORK_NAME = "Bsc Testnet";
// export const CURRENCY = "BNB";
// export const NETWORK_DECIMALS = 18;
// export const EXPLORER_LINK = "https://testnet.bscscan.com/";

// CrossFi Testnet
export const RPC_URL =
  "https://crossfi-testnet.g.alchemy.com/v2/zcNDbEZOJ1RoaCVXGzkEWS3oskcis9Zp";
export const CHAIN_ID = 4157;
export const NETWORK_NAME = "CrossFi";
export const CURRENCY = "XFI";
export const NETWORK_DECIMALS = 18;
export const EXPLORER_LINK = " https://test.xfiscan.com/";
export const COMPTROLLER_ADDRESS = "0xc163cD5f85dBdB2cb589425Ac72b774b886D681D";
export const PRICE_ORACLE_ADDRESS =
  "0x224850bA12b39b885743c3A1bb424fA525808DA6";
export const FACTORY_ADDRESS = "0x682cB6Ca5C2e46f3c442f0cb886DBC6d17B5e838";

//CrossFi Mainnet
// export const RPC_URL =
//   "https://crossfi-mainnet.g.alchemy.com/v2/zcNDbEZOJ1RoaCVXGzkEWS3oskcis9Zp";
// export const CHAIN_ID = 4158;
// export const NETWORK_NAME = "CrossFi";
// export const CURRENCY = "XFI";
// export const NETWORK_DECIMALS = 18;
// export const EXPLORER_LINK = "https://xfiscan.com/";
// export const COMPTROLLER_ADDRESS = "0x7C24D649fC875B2F66cE0b812852E9Cde93e80dd";
// export const PRICE_ORACLE_ADDRESS =
//   "0x62AD71dbB9b01EE7737DE4144DB5Be8D11488aEa";
// export const FACTORY_ADDRESS = "0xEc3e72472A7959050eD0eBEcF249a6f1afbb67aD";

export const SECRET_KEY1 = "],k%.XA!]S!bwD2}2NL[]$";
export const SECRET_KEY2 = "2)h@R_Cy}gpy@^{BXnKbPw";
export const SECRET_KEY3 = "u8fDtq4TidnivB5bqCxk5y";
export const SECRET_KEY4 = "ekRkiLnaBaet8mBDrhDk3Ufj";
export const LIMIT = 10;
export const LENGTH = 128;
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const PROJECT_ID = "551b3056632250352203d9e6d1178ae3";
