import { Dispatch } from "@reduxjs/toolkit";
import { useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import { modalPropTypes } from "../../../../Types/componentPropTypes/modalPropType";
import { RootState } from "../../../../Types/reduxStateType/RootState";
import { callContractSendMethod } from "../../../../redux/Actions/contract.action";
import {
  allowOnlyNumberWithDecimalsInput,
  convertWithDecimal,
  divideBigNumber,
  divideBigNumberWithSuffixes,
  getFailureErrorMsg,
  intToSuffixes,
} from "../../../../services/common.service";
import { ZERO_ADDRESS } from "../../../../utils/Constant";
import Button from "../../button/Button";
import InputCustom from "../../commonInputs/InputCustom";
import CommonModal from "../../commonModal/CommonModal";
import Shimmer from "../../shimmer/Shimmer";
import toast from "../../toast";
import ConfirmTransactionModal from "../confirmTransactionModal/ConfirmTransactionModal";
import "./SupplyWithdrawModal.scss";
import toaster from "../../toast";

const SupplyWithdrawModal = ({
  item,
  borrowLimitUsed,
  borrowRemaining,
  borrowUsed,
  callback,
}: modalPropTypes) => {
  const dispatch: Dispatch<any> = useDispatch();
  const { connector } = useAccount();
  const walletAddress = useSelector(
    (state: RootState) => state.user.walletAddress
  );
  const usdDecimals = useSelector(
    (state: RootState) => state.token.usdDecimals
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modalKey, setModalKey] = useState<any>("");
  const [tabsData, setTabsData] = useState(true);
  const [show, setShow] = useState(false);
  const onHide = () => {
    setShow(false);
    setAmount(0);
  };
  const [amount, setAmount] = useState<any>(0);

  const handleAmountchange = (e: any) => {
    let value = e.target.value;
    if (value.startsWith(".")) {
      value = `0${value}`;
    }
    let valid = allowOnlyNumberWithDecimalsInput(
      value,
      String(item?.decimals).length - 1 || 1
    );
    valid && setAmount(value);
  };

  const handleSupply = async () => {
    setModalKey("supply");
    setShowConfirmation(true);
    onHide();
    const provider = await connector?.getProvider();
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "mint",
        item?.address === ZERO_ADDRESS
          ? []
          : [
              convertWithDecimal(
                amount,
                String(item?.decimals).length - 1 || 1
              ),
            ],
        walletAddress,
        item?.address === ZERO_ADDRESS ? "cether" : "cerc20",
        "supply",
        item?.address === ZERO_ADDRESS
          ? convertWithDecimal(amount, String(item?.decimals).length - 1 || 1)
          : "",
        item?.xToken,
        item?.address,
        item?.address === ZERO_ADDRESS ? true : false
      )
    );

    if (result?.status) {
      if (result?.events?.Failure) {
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        toaster.error(errorMSg);
        setModalKey("error");
      }
      setTimeout(() => callback && callback(), 5000);
      onHide();
      toast.success(`${amount} ${item?.symbol} supplied successfully`);
    } else if (result === undefined) {
      setModalKey("error");
    }
  };

  const handleWithdraw = async () => {
    setModalKey("supply");
    setShowConfirmation(true);
    const provider = await connector?.getProvider();
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        "redeemUnderlying",
        [convertWithDecimal(amount, String(item?.decimals).length - 1 || 1)],
        walletAddress,
        item?.address === ZERO_ADDRESS ? "cether" : "cerc20",
        "supply",
        "",
        item?.xToken
        // item?.address,
        // item?.address === ZERO_ADDRESS ? true : false
      )
    );
    if (result?.status) {
      if (result?.events?.Failure) {
        let errorMSg = getFailureErrorMsg(
          Number(result?.events?.Failure?.returnValues?.info)
        );
        toaster.error(errorMSg);
        setModalKey("error");
      }
      setTimeout(() => callback && callback(), 5000);
      onHide();
      toast.success(`Withdraw successful`);
    } else if (result?.status === undefined) {
      setModalKey("error");
    }
  };

  return (
    <>
      <span
        onClick={() => {
          setShow(true);
        }}
      >
        <img src={item?.logo} alt="" />
        {item?.name}
      </span>
      <CommonModal
        show={show}
        onHide={onHide}
        title={item.name}
        icon={
          <>
            <img src={item?.logo} alt="" />
          </>
        }
        className="supply_withdraw"
      >
        {tabsData ? (
          <div className="widthrow">
            <div className="borrow_heading">
              <InputCustom
                type="text"
                value={amount}
                maxLength={25}
                onChange={handleAmountchange}
                autoFocus
              />
              <button
                onClick={() =>
                  setAmount(
                    divideBigNumber(
                      item?.userBalance ?? "0",
                      String(item?.decimals).length - 1 || 1
                    )
                  )
                }
              >
                Max
              </button>
            </div>
            <p>
              To Supply or Repay {item?.name} Token to the Collatix Protocol,
              you need to enable it first.
            </p>
          </div>
        ) : (
          <div className="widthrow">
            {/* <img src={item?.logo} alt="" /> */}
            <div className="borrow_heading">
              <InputCustom
                type="text"
                value={amount}
                maxLength={25}
                onChange={handleAmountchange}
                autoFocus
              />
              <button
                onClick={() =>
                  setAmount(
                    divideBigNumber(
                      item?.supplyBalance[0]?.balance ?? "0",
                      String(item?.decimals).length - 1 || 1
                    )
                  )
                }
              >
                Max
              </button>
            </div>
          </div>
        )}
        <Tab.Container defaultActiveKey="Supply">
          <Nav>
            <Nav.Item>
              <Nav.Link
                eventKey="Supply"
                onClick={() => {
                  setTabsData(true);
                  setAmount(0);
                }}
              >
                Supply
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="Withdraw"
                onClick={() => {
                  setTabsData(false);
                  setAmount(0);
                }}
              >
                Withdraw
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="Supply">
              <div className="borrow_values">
                <h3>Supply Rates</h3>
                <ul className="borrow_list">
                  <li>
                    <h4>Supply APY</h4>
                    <h6>
                      {item?.apy ? (
                        <>{parseFloat(item?.apy).toFixed(2)}%</>
                      ) : (
                        <Shimmer width={45} height={20} />
                      )}
                    </h6>
                  </li>
                </ul>
                <h3>Borrow Limit</h3>
                <ul>
                  <li>
                    <h4>Borrow Limit</h4>
                    <h6>
                      ${intToSuffixes(borrowRemaining + borrowUsed)}
                      {/* <BlueRightIcon />
                      $0.00 */}
                    </h6>
                  </li>
                  <li>
                    <h4>Borrow Limit Used</h4>
                    <h6>{borrowLimitUsed ? borrowLimitUsed.toFixed(2) : 0}%</h6>
                  </li>
                </ul>
                <div className="progress_line">
                  <span
                    style={{
                      width: `${borrowLimitUsed ? borrowLimitUsed : 0}%`,
                    }}
                  ></span>
                </div>
              </div>
              <Button
                fluid
                className="supply_btn"
                onClick={() => {
                  handleSupply();
                  onHide();
                }}
                disabled={
                  !walletAddress ||
                  !amount ||
                  Number(amount) === 0 ||
                  Number(amount) >
                    Number(
                      divideBigNumber(
                        item?.userBalance,
                        String(item?.decimals).length - 1 || 1
                      )
                    )
                }
              >
                Supply
              </Button>
              {!walletAddress && (
                <h4 className="warning-text">
                  Please connect wallet to continue.
                </h4>
              )}
              {walletAddress && (!amount || Number(amount) === 0) ? (
                <h4 className="warning-text">
                  Please enter amount to continue.
                </h4>
              ) : (
                ""
              )}
              {walletAddress &&
              amount &&
              Number(amount) >
                Number(
                  divideBigNumber(
                    item?.userBalance,
                    String(item?.decimals).length - 1 || 1
                  )
                ) ? (
                <h4 className="warning-text">
                  The entered amount exceeds your wallet balance.
                </h4>
              ) : (
                ""
              )}
              <div className="currently_borrow">
                <h5>Wallet Balance</h5>
                <label
                  title={String(
                    divideBigNumber(
                      item?.userBalance ?? "0",
                      String(item?.decimals).length - 1 || 1
                    )
                  )}
                >
                  {" "}
                  {item?.userBalance ? (
                    divideBigNumberWithSuffixes(
                      item?.userBalance ?? "0",
                      String(item?.decimals).length - 1 || 1,
                      true
                    )
                  ) : (
                    <Shimmer width={45} height={20} />
                  )}{" "}
                  {item?.symbol}
                </label>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="Withdraw">
              <div className="borrow_values">
                <h3>Supply Rates</h3>
                <ul>
                  <li>
                    <h4>Supply APY</h4>
                    <h6>
                      {item?.apy ? (
                        <>{parseFloat(item?.apy).toFixed(2)}%</>
                      ) : (
                        <Shimmer width={45} height={20} />
                      )}
                    </h6>
                  </li>
                </ul>
              </div>
              <Button
                fluid
                className="supply_btn"
                onClick={() => {
                  handleWithdraw();
                  onHide();
                }}
                disabled={
                  amount === 0 ||
                  !walletAddress ||
                  !amount ||
                  Number(amount) === 0 ||
                  Number(amount) >
                    Number(
                      divideBigNumber(
                        item?.supplyBalance[0]?.balance ?? "0",
                        String(item?.decimals).length - 1 || 1
                      )
                    )
                }
              >
                Withdraw
              </Button>
              {!walletAddress && (
                <h4 className="warning-text">
                  Please connect wallet to continue.
                </h4>
              )}
              {walletAddress && (!amount || Number(amount) === 0) && (
                <h4 className="warning-text">
                  Please enter amount to continue.
                </h4>
              )}
              {walletAddress &&
                Number(amount) !== 0 &&
                Number(amount) >
                  Number(
                    divideBigNumber(
                      item?.supplyBalance[0]?.balance ?? "0",
                      String(item?.decimals).length - 1 || 1
                    )
                  ) && (
                  <h4 className="warning-text">
                    The entered amount exceeds your supply balance.
                  </h4>
                )}
              <div className="currently_borrow">
                <h5>Supply Balance</h5>
                <label
                  title={String(
                    item?.supplyBalance
                      ? divideBigNumber(
                          item?.supplyBalance[0]?.balance ?? "0",
                          String(item?.decimals).length - 1 || 1
                        )
                      : 0
                  )}
                >
                  {item?.supplyBalance ? (
                    intToSuffixes(divideBigNumberWithSuffixes(
                      item?.supplyBalance[0]?.balance ?? "0",
                      String(item?.decimals).length - 1 || 1,
                    ),3)
                  ) : (
                    <Shimmer width={45} height={20} />
                  )}{" "}
                  {item?.symbol}
                </label>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </CommonModal>
      {showConfirmation && (
        <ConfirmTransactionModal
          modalKey={modalKey}
          show={showConfirmation}
          onHide={() => setShowConfirmation(false)}
        />
      )}
    </>
  );
};

export default SupplyWithdrawModal;
